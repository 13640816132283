import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Single Leg Squats 8-8-8/leg`}</p>
    <p>{`Single Leg RDL’s 8-8-8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`40-30-20-10 reps each of:`}</p>
    <p>{`Step ups, total (24/20″)`}</p>
    <p>{`V Ups`}</p>
    <p>{`Wall Balls (20/14)`}</p>
    <p>{`Double Unders`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar with Dawn from Sports Nutrition 2 Go in Cincinnati.
Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
We will need 20 people in order to have this seminar so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend. \\$35/person
for the seminar.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      